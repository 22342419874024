import Swiper, { Navigation, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

class BridgesCarousel {
  constructor(carousel) {
    this.$carousel = carousel;
    this.setCarousel();
  }

  setCarousel() {
    const swiper = new Swiper(this.$carousel, {
      modules: [Navigation, A11y],
      direction: "horizontal",
      slidesPerView: 'auto',
      slidesPerGroup: 3,
      centeredSlides: false,
      spaceBetween: 0,
      loopedSlides: 6,
      navigation: {
        nextEl: ".c-slider__next",
        prevEl: ".c-slider__prev",
      },
      a11y: {
        prevSlideMessage: "Previous group of bridges",
        nextSlideMessage: "Next group of bridges",
        containerRoleDescriptionMessage: 'Carousel of the Illuminated River bridges',
        slideRole: 'slide',
        slideLabelMessage: 'Bridge {{index}} of {{slidesLength}}'
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      breakpoints: {
        // when window width is <= 320px
        1024: {
          // slidesPerView: 4,
          slidesPerGroup: 2,
        },
        // when window width is <= 414px
        414: {
          // slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    });
  }
}

export default BridgesCarousel;
