import InfiniteScroll from "infinite-scroll";

class Infinite {
  constructor(infinite) {
    this.$infinite = infinite;
    this.$loadmore = document.querySelector('.js-loadmore');
    if (this.$loadmore) this.setInfinite();
  }

  setInfinite() {
    let infScroll = new InfiniteScroll(this.$infinite, {
      path: ".js-loadmore",
      append: ".item",
      button: ".js-loadmore",
      scrollThreshold: false,
    });

    infScroll.on("append", function (body, path, items, response) {
      let firstItem = items[0];
      firstItem.querySelector("a").focus();
    });
  }
}

export default Infinite;
