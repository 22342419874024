import Swiper, { Navigation, Pagination, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

class Carousel {
  constructor(carousel) {
    this.$carousel = carousel;
    this.setCarousel();
  }

  setCarousel() {
    const swiper = new Swiper(this.$carousel, {
      modules: [Navigation, Pagination, A11y],
      direction: "horizontal",
      navigation: {
        nextEl: ".c-carousel__next",
        prevEl: ".c-carousel__prev",
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
      },
      zoom: true,
    });
  }
}

export default Carousel;
