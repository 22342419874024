"use strict";
import 'normalize.css';
import '/src/css/style.scss';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import Navigation from './modules/Navigation';
import Carousel from './modules/Carousel';
import Slideshow from './modules/Slideshow';
import Infinite from './modules/Infinite';
import BridgesCarousel from './modules/BridgesCarousel';
import BridgesGraphic from './modules/BridgesGraphic';


new Navigation();

(function() {
  document.documentElement.className =
    document.documentElement.className.replace(/\bno-js\b/g, "") + " js ";
})();

const carouselModules = document.querySelectorAll('.js-carousel');
if (carouselModules) { carouselModules.forEach(carouselModule => new Carousel(carouselModule))}

const slideshowModules = document.querySelectorAll('.js-slideshow');
if (slideshowModules) { slideshowModules.forEach(slideshowModule => new Slideshow(slideshowModule))}

const bridgesCarouselModules = document.querySelectorAll('.js-bridges-carousel');
if (bridgesCarouselModules) { bridgesCarouselModules.forEach(bridgesCarouselModule => new BridgesCarousel(bridgesCarouselModule))}

const bridgesGraphicModules = document.querySelectorAll('.js-river-map');
if (bridgesGraphicModules) { bridgesGraphicModules.forEach(bridgesGraphicModule => new BridgesGraphic(bridgesGraphicModule))}

const infiniteScrollModules = document.querySelectorAll('.js-infinitescroll');
if (infiniteScrollModules) { infiniteScrollModules.forEach(infiniteScrollModule => new Infinite(infiniteScrollModule))}

document.addEventListener("DOMContentLoaded", function(){
  document.addEventListener("touchstart", function () {}, true);
});
