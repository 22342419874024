import Swiper, { Autoplay, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";

class Slideshow {
  constructor(slideshow) {
    this.$slideshow = slideshow;
    this.setSlideshow();
  }

  setSlideshow() {
    const swiper = new Swiper(this.$slideshow, {
      modules: [Autoplay,EffectFade],
      spaceBetween: 0,
      effect: "fade",
      speed: 2500,
      fadeEffect: {
        crossFade: false,
      },
      allowTouchMove: false,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      }
    });
  }
}


export default Slideshow;
