class Navigation {
  constructor() {
    this.$masthead = document.querySelector(".c-masthead");
    this.$html = document.getElementsByTagName("html")[0];
    this.$content = document.querySelector("#content");
    this.$footer = document.querySelector(".c-footer");
    this.$navigation = document.querySelector(".c-masthead__nav");
    this.$toggle = document.querySelector(".js-masthead__toggle");
    this.$search = document.querySelector(".c-masthead__search");
    this.$searchToggle = document.querySelector(".js-search__toggle");
    this.$searchField = document.querySelector(".c-search__field");

    this.setNavigation();
  }

  showNavigation() {
    this.$html.classList.add("has-masthead-open");
    this.$masthead.setAttribute("aria-expanded", "true");
    this.$content.setAttribute("aria-hidden", "true");
    this.$footer.setAttribute("aria-hidden", "true");
    this.$navigation.setAttribute("aria-hidden", "false");
    this.trapFocus(this.$navigation);
    this.$toggle.focus();
  }

  hideNavgigation() {
    this.$html.classList.remove("has-masthead-open");
    this.$masthead.setAttribute("aria-expanded", "false");
    this.$content.setAttribute("aria-hidden", "false");
    this.$footer.setAttribute("aria-hidden", "false");
    this.$navigation.setAttribute("aria-hidden", "true");
  }

  showSearch() {
    this.$html.classList.add("has-search-open");
    this.$masthead.setAttribute("aria-expanded", "true");
    this.$content.setAttribute("aria-hidden", "true");
    this.$footer.setAttribute("aria-hidden", "true");
    this.$navigation.setAttribute("aria-hidden", "false");
    this.trapFocus(this.$search);
    this.$searchField.focus();
  }

  hideSearch() {
    this.$html.classList.remove("has-search-open");
    this.$masthead.setAttribute("aria-expanded", "false");
    this.$content.setAttribute("aria-hidden", "false");
    this.$footer.setAttribute("aria-hidden", "false");
    this.$navigation.setAttribute("aria-hidden", "true");
    this.$searchField.blur();
  }

  escapeModal(Event) {
    if (Event.key === "Escape") {
      this.hideNavgigation();
      this.hideSearch();
    }
  }

  trapFocus(modal) {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const $modal = modal;
    const $firstFocusableElement =
      $modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const $focusableContent = $modal.querySelectorAll(focusableElements);
    const $lastFocusableElement =
      $focusableContent[$focusableContent.length - 1]; // get last element to be focused inside modal
    const $toggle = this.$toggle;

    function setTrapFocus(Event) {
      let isTabPressed = Event.key === "Tab" || Event.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (Event.shiftKey) {
        // if shift key pressed for shift + tab combination
        if (document.activeElement === $toggle) {
          $lastFocusableElement.focus(); // add focus for the last focusable element
          Event.preventDefault();
        }
        else if (document.activeElement === $firstFocusableElement) {
          $toggle.focus();
          Event.preventDefault();
        }
      } else {
        // if tab key is pressed
        if (document.activeElement === $lastFocusableElement) {
          // if focused has reached to last focusable element then focus first focusable element after pressing tab
          $toggle.focus(); // add focus for the first focusable element
          Event.preventDefault();
        }
      }
    }

    document.addEventListener("keydown", (event) => {
      setTrapFocus(event);
    });
  }

  toggleNavigation() {
    // Checks if already opened, then closes
    if (this.$html.classList.contains("has-masthead-open")) {
      this.hideNavgigation();
    }
    // Checks if search is opened, then closes it
    else if (this.$html.classList.contains("has-search-open")) {
      this.hideSearch();
      this.hideNavgigation();
    }
    // Opens the menu
    else {
      this.showNavigation();
    }
  }

  toggleSearch() {
    // Checks if already opened, then closes
    if (this.$html.classList.contains("has-search-open")) {
      this.hideSearch();
    }
    // Checks if masthead is opened, then closes it
    else if (this.$html.classList.contains("has-masthead-open")) {
      this.hideNavgigation();
      this.showSearch();
    }
    // Opens the menu
    else {
      this.showSearch();
    }
  }

  setNavigation() {
    document.addEventListener("keyup", (event) => {
      this.escapeModal(event);
    });
    this.$toggle.addEventListener("click", () => {
      this.toggleNavigation();
    });
    this.$searchToggle.addEventListener("click", () => {
      this.toggleSearch();
    });
  }
}

export default Navigation;
