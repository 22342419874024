class BridgesGraphic {
  constructor(bridgesGraphic) {
    this.$river = bridgesGraphic;
    this.$bridges = this.$river.querySelectorAll(".bridge");
    this.setBridgesGraphic();
  }

  switchBridgeOn(event) {
    console.log(event.type)
    if (event.type == "mouseenter") {
      this.$river.classList.add("is-hover");
    }
    let label = event.target.getAttribute("id");

    document.getElementById(label + "-label").classList.add("is-active");
    let otherBridges = document.querySelectorAll(".c-river__bridge:not(#" + label + "-label");
    otherBridges.forEach(function (bridge) {
      bridge.classList.remove("is-active");
      bridge.classList.add("is-inactive");
    });
  }

  switchBridgeOff(event) {
    if (event.type == "mouseleave") {
      this.$river.classList.remove("is-hover");
    }
    let label = event.target.getAttribute("id");
    document.getElementById(label + "-label").classList.remove("is-active");
    let otherBridges = document.querySelectorAll(".c-river__bridge:not(#" + label + "-label");
    otherBridges.forEach(function (bridge) {
      bridge.classList.remove("is-inactive");
    });
  }

  setBridgesGraphic() {
    this.$bridges.forEach((bridge) => {
      bridge.setAttribute("tabindex", "0");
      bridge.addEventListener("mouseleave", (event) => { this.switchBridgeOff(event);})
      bridge.addEventListener("blur", (event) => { this.switchBridgeOff(event);})
      bridge.addEventListener("mouseenter",  (event) => { this.switchBridgeOn(event);})
      bridge.addEventListener("focus", (event) => {  this.switchBridgeOn(event);})
    });
  }
}

export default BridgesGraphic;
